<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Devolução Material Expedição
        <v-spacer />
        <v-row
          class="d-flex justify-end"
        >
          <v-col
            cols="12"
            md="6"
            sm="6"
          >
            <v-text-field
              v-model="search"
              v-mask="'##/##/####-###-###'"
              label="Orçamento"
              single-line
              hide-details
              autofocus
              @keydown.enter="getCodigoOrcamento"
            >
              <template v-slot:append>
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      icon
                      medium
                      color="primary"
                      class="mt-0 pt-0"
                      @click="dialogCodgiBarras = true"
                    >
                      <v-icon v-on="on">
                        mdi-barcode
                      </v-icon>
                    </v-btn>
                  </template>
                  Pesquisar
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <!-- MODAL DA GRID -->
    <v-card>
      <v-data-table
        dense
        :headers="headers"
        :items="listaPedido"
        :search="search"
      >
        <template v-slot:item.porcentagem="{ item }">
          <v-progress-circular
            :rotate="-90"
            :size="30"
            :width="3"
            :value="item.porcentagem"
            color="teal"
          />
        </template>
        <template v-slot:item.vlTotal="{ item }">
          {{ formatPrice(item.vlTotal) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="onClickSelect(item)"
          >
            mdi-truck-fast-outline
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <!-- MODAL DA ITENS -->
    <v-dialog
      v-model="dialogExpedicao"
      max-width="1200"
    >
      <modelItemExpedicao
        v-if="dialogExpedicao"
        :idorcamento="idorcamento"
        :orcamentoformatado="orcamentoformatado"
        :flselecionarpesador="true"
        :flseparar="flseparar"
        :liberadaestorno="telaHabAlt"
        :lojaselecionada="lojaselecionada"
        :codigovendedor="codigoVendedor"
        :stacao="'E'"
        @onSairExpedicao="onSairExpedicao"
        @onSucessoExpedicao="onSucessoExpedicao"
      />
    </v-dialog>
    <!-- MODAL DA CODIGO DE BARRAS -->
    <v-dialog
      v-model="dialogCodgiBarras"
      fullscreen
    >
      <modalLeituraCodBarra @onPesquisaCodigoBarras="onPesquisaCodigoBarras" />
    </v-dialog>
    <!-- MODAL DA LOJA -->
    <v-dialog
      v-model="dialogLoja"
      max-width="300"
      persistent
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
        >
          Selecione a Loja
        </v-toolbar>
        <v-card-text>
          <componenteLoja
            :id="loja"
            dense
            :flparamentrovenda="false"
            @onDadosLoja="onDadosLoja"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import modalLeituraCodBarra from '../../componentes/componente-Leitura-CodigoBarra.vue'
  // import calendario from '../../componentes/util/datacalendario.vue'
  import pedidoRep from '../../../components/services/orcamentoRepository'
  import transacaoRep from '../../../components/services/transacaoRepository'
  import pesadorRep from '../../../components/services/pesadorRepository'
  import modelItemExpedicao from '../../componentes/modal-item-expedicao.vue'
  import componenteLoja from '../../componentes/componente-loja.vue'

  export default {
    components: {
      // calendario,
      modelItemExpedicao,
      modalLeituraCodBarra,
      componenteLoja,
    },
    data () {
      return {
        dialogCodgiBarras: false,
        dataAtual: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        title: 'Separar Item Material',
        headers: [
          {
            text: 'Orçamento',
            align: 'start',
            value: 'pedidoFormatado',
            width: 130,
          },
          {
            text: 'Cliente',
            align: 'start',
            value: 'nmCliente',
            width: 180,
          },
          {
            text: 'Vl. Total',
            align: 'end',
            value: 'vlTotal',
            width: 180,
          },
          {
            text: 'Vendedor',
            align: 'start',
            value: 'nmVendedor',
            width: 100,
          },
          {
            align: 'start',
            value: 'porcentagem',
            width: 30,
          },
          { text: '', value: 'actions', width: 100, align: 'end', sortable: false },
        ],
        listaPedido: [],
        idorcamento: 0,
        orcamentoformatado: '',
        dialogExpedicao: false,
        dialogExpedicaoLiberacao: false,
        search: '',
        dialogLoja: false,
        loja: 0,
        lojaselecionada: 0,
        dadosLoja: {},
        flseparar: false,
        telaLiberada: false,
        telaHabAlt: false,
        codigoVendedor: 0,
      }
    },
    async created () {
      this.dataSelecionada = this.formatDate(this.dataAtual)
    },
    async mounted () {
      this.dialogLoja = true
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        // VERIFICA SE USUARIO TEM PERMISSÃO
        await this.onGetTransacaoUsuario()
        if (this.telaLiberada === false) {
          this.$router.replace('/v1')
        }
      }
      this.telaLiberada = true
    },
    methods: {
      async onGetTransacaoUsuario () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FP165DEV',
        }
        await transacaoRep.getTransacao(dados).then(response => {
          this.telaLiberada = true
          if (response.data.flHabAlt === true) { this.telaHabAlt = true } else { this.telaHabAlt = false }
        }).catch(Error => {
          this.$store.commit('setSplashScreen', false)
          this.telaLiberada = false
          this.$swal({
            icon: 'error',
            text: 'Acesso não permitido, para solicitar o acesso passar o seguinte código, FP165DEV.',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) return false
          })
        })
      },
      onDataSelecionada (data) {
        this.dataSelecionada = data
        this.onCarregarConsulta()
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      async onCarregarPesador () {
        try {
          this.$store.commit('setSplashScreen', true)
          await pesadorRep.getPorUsuario(this.$store.getters.usuario.cdusuario, this.lojaselecionada).then(response => {
            this.$store.commit('setSplashScreen', false)
            this.codigoVendedor = response.data.cdPesador
          })
        } catch (error) {
          // debugger
          // console.log(error.response.data.detail)
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'warning',
            text: 'É necessário um Pesador para utilizar esta tela!',
            confirmButtonText: 'OK',
          }).then((result) => {
            this.$router.replace('/v1')
          })
        }
      },
      async getCodigoOrcamento () {
        this.$store.commit('setSplashScreen', true)
        try {
          await pedidoRep.RetornaIdOrcamento(this.search).then(response => {
            this.$store.commit('setSplashScreen', false)
            this.idorcamento = response.data
            this.orcamentoformatado = this.search
            this.dialogExpedicao = true
            this.search = ''
          })
        } catch (error) {
          // debugger
          // console.log(error.response.data.detail)
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error.response.data.detail,
          })
          this.search = ''
        }
      },
      async onCarregarConsulta () {
        this.$store.commit('setSplashScreen', true)
        await pedidoRep.getListPedidosExpedicaoDevolucaoPorStatus(this.dataSelecionada, this.dadosLoja.cdLoja, 'F').then(response => {
          if (response.data.status === 'sucesso') {
            this.$store.commit('setSplashScreen', false)
            this.listaPedido = response.data.dados
          } else if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
            })
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
            })
          } else {
            this.$swal({
              icon: 'error',
              text: response.data.mensagemErro,
            })
            this.$store.commit('setSplashScreen', false)
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
      onExpedicao (pedido) {
        this.dialogExpedicao = true
        this.pedido = pedido
      },
      onSairExpedicao () {
        this.dialogExpedicao = false
      },
      onSucessoExpedicao () {
        this.dialogExpedicao = false
      },
      onClickSelect (item) {
        this.idorcamento = item.cdOrcamento
        this.orcamentoformatado = item.pedidoFormatado
        this.dialogExpedicao = true
      },
      onPesquisaCodigoBarras (codigoBarras) {
        // console.log(codigoBarras)
        this.dialogCodgiBarras = false
        this.search = codigoBarras
      },
      onDadosLoja (dados) {
        this.dadosLoja = dados
        this.flseparar = dados.flSeparacao
        this.lojaselecionada = dados.cdLoja
        this.dialogLoja = false
        this.onCarregarConsulta()
        this.onCarregarPesador()
      },
    },
  }
</script>
